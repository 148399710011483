export const OVERLAY_OPACITY = 0.75;
export const OVERLAY_PADDING = 10;

export const SHOULD_ANIMATE_OVERLAY = true;
export const SHOULD_OUTSIDE_CLICK_CLOSE = true;
export const ALLOW_KEYBOARD_CONTROL = true;
export const SHOULD_OUTSIDE_CLICK_NEXT = false;

export const ESC_KEY_CODE = 27;
export const LEFT_KEY_CODE = 37;
export const RIGHT_KEY_CODE = 39;

export const ID_OVERLAY = 'driver-page-overlay';
export const ID_STAGE = 'driver-highlighted-element-stage';
export const ID_POPOVER = 'driver-popover-item';
export const PROGRESS_BAR = 'driver-popover-progress';

export const CLASS_DRIVER_HIGHLIGHTED_ELEMENT = 'driver-highlighted-element';
export const CLASS_POSITION_RELATIVE = 'driver-position-relative';
export const CLASS_FIX_STACKING_CONTEXT = 'driver-fix-stacking';

export const CLASS_STAGE_NO_ANIMATION = 'driver-stage-no-animation';
export const CLASS_POPOVER_TIP = 'driver-popover-tip';
export const CLASS_POPOVER_TITLE = 'driver-popover-title';
export const CLASS_POPOVER_DESCRIPTION = 'driver-popover-description';
export const CLASS_POPOVER_FOOTER = 'driver-popover-footer';
export const CLASS_CLOSE_BTN = 'driver-close-btn';
export const CLASS_NEXT_STEP_BTN = 'driver-next-btn';
export const CLASS_PREV_STEP_BTN = 'driver-prev-btn';
export const CLASS_BTN_DISABLED = 'driver-disabled';
export const CLASS_CLOSE_ONLY_BTN = 'driver-close-only-btn';
export const CLASS_NAVIGATION_BTNS = 'driver-navigation-btns';
export const POPOVER_PROGRESS_ITEM = 'popover-progress-item';
export const CLASS_READ_FLAG_VALUE = 'class-read-flag-value';

// NOTE: It must match the one set in the animations in CSS file
export const ANIMATION_DURATION_MS = 30;

// language=HTML
export const processData = [
  {
    title: '添加客户',
    classNames: [POPOVER_PROGRESS_ITEM],
    vid: 'customerlist',
  },
  {
    title: '添加联系人',
    classNames: [POPOVER_PROGRESS_ITEM],
    vid: 'customercontactPersonlist',
  },
  {
    title: '添加设备',
    classNames: [POPOVER_PROGRESS_ITEM],
    vid: 'devicelist',
  },
  {
    title: '添加员工',
    classNames: [POPOVER_PROGRESS_ITEM],
    vid: 'systememployeelist',
  },
  {
    title: '初始化完成',
    classNames: [POPOVER_PROGRESS_ITEM],
    vid: 'workOrderlist',
  },
];
const renderProcess = (data = []) => {
  if (!data || !data.length) return null;
  let temp = '';
  data.forEach((i) => {
    temp += `<div class="${i.classNames.join(' ')} ${i.vid}" data-id="${i.vid}">
              <span>${i.title}</span>
              <i class="iconfont icon-tishi"></i><span class="message">该账号没有相关操作权限，已跳过此步骤。</span>
            </div>`;
  });
  return temp;
};
export const POPOVER_HTML = (className = '', showProgress) => `
  <div id="${ID_POPOVER}" class="${className}">
    <div class="${CLASS_POPOVER_TIP}"></div>
    <div class="${CLASS_POPOVER_TITLE}">Popover Title</div>
    <div class="${CLASS_POPOVER_DESCRIPTION}">Popover Description</div>
    <div class="driver-clearfix ${CLASS_POPOVER_FOOTER}">
      <span class="driver-btn-group ${CLASS_NAVIGATION_BTNS}">
        <div class="tips_btn checkout_container">
          <input type="checkbox" id="${CLASS_READ_FLAG_VALUE}">
          <span class="check_text">下次登录不再提示</span>
        </div>
        <div class="${CLASS_NEXT_STEP_BTN + ' tips_btn close_btn'}">下一步 &gt;</div>
        <button class="${CLASS_PREV_STEP_BTN}">&larr; Previous</button>
        <div class="${CLASS_CLOSE_BTN} tips_btn close_btn">关闭引导</div>
      </span>
    </div>
    <div id="${PROGRESS_BAR}" style="display: ${showProgress ? 'block' : 'none'}">
      <div class="popover-progress-ul">
        ${renderProcess(processData)}
      </div>
    </div>
  </div>`;

export const OVERLAY_HTML = `<div id="${ID_OVERLAY}"></div>`;
export const STAGE_HTML = `<div id="${ID_STAGE}"></div>`;
