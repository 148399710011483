// auth 字段做ID，新手引导
export default [
  {
    vid: 'customerlist',
    title: '客户档案',
    tips: '客户列表，可新增、查看所有的客户信息，点击客户名称可进入到详细信息页面。',
    pathName: '/customer/list',
    pst: 'right',
  },

  {
    vid: 'customercontactPersonlist',
    title: '客户联系人列表',
    tips: '客户联系人列表，在此处新增客户联系人后，客户方可使用手机端发起工单，点击联系人姓名，进入到该联系人详细信息页面。',
    pathName: '/contact/list',
    pst: 'right',
  },
  {
    vid: 'devicelist',
    title: '资产台账',
    tips: '资产台账，用于管理所有服务客户的设备信息，新增设备后，客户方可在手机端选择设备发起工单。',
    pathName: '/customForm/list/DEVICE_FILE',
    pst: 'right',
    // children: [
    //   {
    //     vid: 'devicelist_one',
    //     title: '批量下载二维码',
    //     tips: '新增设备后可生成二维码，此处批量下载二维码后，粘贴与客户设备上，客户可直接扫码发起工单！',
    //     pst: 'right',
    //     permission: 'deviceadd', // 页面内按钮步骤权限以 permission 匹配 去除 : 和 _
    //   },
    // ],
  },
  {
    vid: 'systememployeelist',
    title: '成员管理',
    tips: '成员管理，管理您所有的员工信息，添加员工后，可为其分配对应权限，如派单员、工程师等。',
    pathName: '/systemSetting/employee/list',
    pst: 'right',
  },
  {
    vid: 'workOrderlist',
    title: '工单列表',
    tips: '工单列表，登录后默认进去工单列表页面，此处可管理所有的工单信息，主要由管理人员和派单员使用。',
    pathName: '/order/list',
    pst: 'right',
  },
  {
    vid: 'systemuserInfo',
    title: '客户端设置',
    tips: '用户信息，此处维护您的公司信息，包含客服电话、联系地址、品牌LOGO、二维码等，以上信息会展示在手机端展示给您的客户查看。',
    pathName: '/systemSettings/userInfo',
    pst: 'right',
  },

  {
    vid: 'systemledgerlist',
    title: '账套信息',
    tips: '账套信息，展示您所有的订单与开票信息，未支付的订单可在此处继续支付，支付完成后可继续申请开具发票。',
    pathName: '/systemSetting/account/list',
    pst: 'right',
  },
  {
    vid: 'systemdepartmentlist',
    title: '部门列表',
    tips: '部门列表，管理您公司内部的部门，添加部门后，可在部门下增加人员。',
    pathName: '/systemSetting/department/list',
    pst: 'right',
  },
  {
    vid: 'systemrolelist',
    title: '角色权限',
    tips: '角色权限，我们为您预制了常用的角色权限，您也可自定义新的角色用来实现更加细化的权限管理。',
    pathName: '/systemSetting/role/list',
    pst: 'right',
  },
  {
    vid: 'personalcenter',
    title: '个人中心',
    tips: '个人中心，进入个人中心可修改您的登录密码；',
    pst: 'left',
    parentId: 'personl_help_btn',
    bgColor: '#fff',
    noAuth: true, // 不需要权限配置
    needDrop: true, // 需要保持展开状态
  },
  {
    vid: 'helpcenter',
    title: '帮助中心',
    tips: '帮助，如有任何使用问题，可在此处查看帮助手册，或给我们提供意见反馈。',
    pst: 'left',
    parentId: 'guide_help_btn',
    bgColor: '#fff',
    noAuth: true,
    needDrop: true, // 需要保持展开状态
  },
];
