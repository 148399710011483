<template>
  <div class="wrapper">
    <data-item v-for="item in mainData" :key="item.label" :data="item" />
  </div>
</template>

<script>
import { getIndicatorInfo } from '@/api/bigScreen.js';
import dataItem from './dataItem.vue';
import dayjs from 'dayjs';

export default {
  name: 'mainStatistics',
  components: {
    dataItem,
  },
  inject: ['provinceNameFn', 'periodFn'],
  computed: {
    provinceName () {
      return this.provinceNameFn();
    },
    period () {
      return this.periodFn();
    },
  },
  watch: {
    provinceName: {
      handler () {
        this.getInfo();
      },
    },
    period: {
      handler () {
        this.getInfo();
      },
    },
  },
  data () {
    return {
      mainData: [
        {
          label: '客户数量',
          growthRate: 0.5,
          quantitiy: 0,
          unit: '人',
        },
        {
          label: '设备数量',
          growthRate: 0.5,
          quantitiy: 0,
          unit: '台',
        },
        {
          label: '工单数量',
          growthRate: 0.5,
          quantitiy: 0,
          unit: '单',
        },
      ],
      lastRefresh: '',
    };
  },
  methods: {
    async getInfo () {
      let { body } = await getIndicatorInfo({ provinceName: this.provinceName, period: this.period });
      this.mainData = [
        {
          label: '客户数量',
          growthRate: body['客户数量'].growthRate,
          quantitiy: body['客户数量'].quantity,
          unit: '家',
        },
        {
          label: '设备数量',
          growthRate: body['设备数量'].growthRate,
          quantitiy: body['设备数量'].quantity,
          unit: '台',
        },
        {
          label: '工单数量',
          growthRate: body['工单数量'].growthRate,
          quantitiy: body['工单数量'].quantity,
          unit: '单',
        },
      ];
    },
    refreshItems () {
      let date = dayjs().valueOf();
      if (!this.lastRefresh || (date - this.lastRefresh) > 60 * 1000) {
        this.lastRefresh = date;
        this.getInfo();
      }
      this.timerId = requestAnimationFrame(this.refreshItems);
    },
  },
  mounted () {
    this.refreshItems();
  },
  destroyed () {
    cancelAnimationFrame(this.timerId);
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  box-sizing: border-box;
  width: 100%;
  height: vh(160);
  background-image: url('../../../../assets/imgs/dashboard/midbg.png');
  background-size: vw(770) 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding: vh(26) vw(156);
}
</style>
