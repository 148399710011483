<template>
  <div :class="`left-menu ${isCollapse ? 'left-menu--collapse' : ''}`">
    <div class="logo" @click="$router.push('/')">
      <img :src="isCollapse ? require('@/assets/logo_short.png') : require('@/assets/logo@3x.png')" />
    </div>
    <el-menu :collapse-transition="false" :collapse="isCollapse" :default-active='defaultActive' class="workbench-menu">
      <el-menu-item index="/workbench" @click="$router.push('/')">
        <i class="icon-ic_shouye_nor1 iconfont-2 iconfont iconfont-3"></i>
        <span slot="title">服务大厅</span>
      </el-menu-item>
    </el-menu>
    <el-menu ref="leftMenu" :collapse-transition="false" :collapse="isCollapse" @select="handleSelectMenu"
      :default-active='defaultActive'>
      <template v-for="item in leftMenuList">
        <el-submenu :index="item.id" v-if="item.children && item.children[0]" :key="item.id + item.name" class="test">
          <template slot="title">
            <i :class="item.icon + ' iconfont-2 iconfont iconfont-3'"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <template v-for="i in item.children">
            <el-submenu :index="i.id" v-if="i.children && i.children[0]" :key="i.id + i.name" class="test">
              <template slot="title">{{ i.name }} </template>
              <el-menu-item :index="j.url" v-for="j in i.children" :key="j.id + j.name">{{ j.name }}</el-menu-item>
            </el-submenu>
            <el-menu-item :id="i.authCode.replace(/\:|\_/g, '')" v-else-if="i.url" :index="i.url" :key="'1-'+i.id + i.name">{{
              i.name }}</el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item v-else-if="item.url" :index="item.url" :key="'2-'+item.id + item.name">
          <i :class="item.icon + ' iconfont-2 iconfont iconfont-3'"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </template>
    </el-menu>
    <div class="bottom" v-if="!isCollapse" @click="goToOfficialWebsite">
      <p><img :src="require('@/assets/earth.svg')">小紫官网</p>
      <p>热线 021-52813076</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'leftMenu',
  props: {
    isCollapse: Boolean,
  },
  data () {
    return {};
  },
  methods: {
    // 判断元素在可视区域
    isInViewPort (el) {
      const viewWidth = window.innerWidth || document.documentElement.clientWidth;
      const viewHeight = window.innerHeight || document.documentElement.clientHeight;
      const {
        top,
        right,
        bottom,
        left,
      } = el.getBoundingClientRect();

      // -200优化底部菜单提示位置
      return (
        top >= 0
        && left >= 0
        && right <= viewWidth
        && bottom <= viewHeight - 200
      );
    },
    // 选中的导航
    handleSelectMenu (nav) {
      const { jobNumber, tenantId, departmentId, id } = this.currentUserInfo;
      if (nav.indexOf('http') === 0) {
        const menu = this.menuUrlMap[nav];
        let menuName = menu && menu[0] ? menu[0].name : '';
        // eslint-disable-next-line no-template-curly-in-string
        if (nav.indexOf('${jobNumber}') >= 0 && !jobNumber) {
          this.$message.warning('当前员工工号不存在');
          return;
        }
        // eslint-disable-next-line no-new-func
        const compileUrl = new Function('jobNumber', 'tenantId', 'departmentId', 'userId', 'return `' + nav + '`');
        this.$tabs.openIframe(compileUrl(jobNumber, tenantId, departmentId, id), menuName);
        return;
      }
      this.$router.push(nav);
    },
    // gobackTop引导定位回到顶部
    gobackTop () {
      this.$refs.leftMenu.$el.scrollTop = 0;
    },
    // 手动控制 z-index 图层
    highCurRoute (step) {
      [...document.querySelectorAll('.el-menu-item')].forEach(i => { i.style['z-index'] = 100; });
      document.querySelector(step.options.element).style['z-index'] = 299999;
      if (step.options?.element && !this.isInViewPort(document.querySelector(step.options.element))) {
        document.querySelector(step.options.element).scrollIntoView();
      }
    },
    goToOfficialWebsite () {
      window.open('https://xz.1xie.com/', '_blank');
    },
  },
  watch: {
    isCollapse () {
      this.$eventBus.$emit('leftMenuCollapse', this.isCollapse);
      if (this.isCollapse) {
        this.$local.set('leftMenuCollapse', this.isCollapse);
      } else {
        this.$local.remove('leftMenuCollapse');
      }
    },
  },
  computed: {
    ...mapGetters([
      'menuTree',
      'menuIdMap',
      'menuUrlMap',
      'currentUserInfo',
    ]),
    defaultActive () {
      let matchedMenuList = this.menuUrlMap[this.$route.path] || [];
      matchedMenuList = matchedMenuList.sort((a, b) => a.type >= b.type);
      let breadcrumbList = [];
      let currentMenu = matchedMenuList[0];
      if (currentMenu && this.leftMenuTreeMap) {
        breadcrumbList.unshift(currentMenu);
        let getParent = menuItem => {
          let parent = this.menuIdMap[menuItem.parentId || menuItem.pid];
          if (parent) {
            breadcrumbList.unshift(parent);
            if (parent.parentId || parent.pid) {
              getParent(parent);
            }
          }
        };
        getParent(currentMenu);
        for (let index = breadcrumbList.length - 1; index >= 0; index--) {
          const menu = breadcrumbList[index];
          if (this.leftMenuTreeMap.get(menu.url)) {
            return menu.url;
          }
        }
      }
      return this.$route.path;
    },
    leftMenuTreeMap () {
      // 缓存当前左侧菜单map
      let leftMenuTreeMap = new Map();
      let cacheMenuTree = (menuList) => {
        (menuList || []).forEach(t => {
          if (t.children) {
            cacheMenuTree(t.children);
          } else if (t.url) {
            leftMenuTreeMap.set(t.url, true);
          }
        });
      };
      cacheMenuTree(this.leftMenuList);
      return leftMenuTreeMap;
    },
    leftMenuList () {
      let {meta} = this.$route;
      let menuList = [];
      let currentModule = this.menuUrlMap[this.$route.path];
      if (currentModule && currentModule[0] && currentModule[0].menuUrl !== '/workbench') {
        menuList = this.menuTree.find(t => t.sysFlag.toUpperCase() === currentModule[0].sysFlag.toUpperCase()).children;
        if (menuList) {
          this.$local.set('backupsLeftMenuList', menuList);
        }
        return menuList;
      } else if (currentModule && currentModule[0] && currentModule[0].menuUrl === '/workbench' && this.$local.get('backupsLeftMenuList')) {
        return JSON.parse(this.$local.get('backupsLeftMenuList'));
      } else {
        menuList = this.menuTree.find(t => t.sysFlag.toUpperCase() === meta.module.toUpperCase())?.children;
        if (menuList) {
          this.$local.set('backupsLeftMenuList', menuList);
        }
        return menuList;
      }
    },
  },
  created () {
    if (this.isCollapse) {
      this.$local.set('leftMenuCollapse', this.isCollapse);
    } else {
      this.$local.remove('leftMenuCollapse');
    }
  },
};
</script>

<style lang='scss'>
.workbench-menu {
  flex: none !important;
}

.el-submenu__title:hover {
  color: #237FFA !important;
}
.el-submenu__title:hover .iconfont-2 {
  color: #237FFA !important;
}
.el-submenu__title:hover .iconfont-3 {
  color: #237FFA !important;
}
.el-menu-item:hover {
  color: #237FFA !important;
}
.left-menu,
.el-menu--popup {
  .el-submenu .el-submenu__title,
  .el-menu-item {
    height: 46px !important;
    line-height: 46px !important;
  }

  .el-submenu__title {
    color: rgba(0, 0, 0, .65);
    font-weight: 600;

    &:focus {
      color: black;

      i {
        color: black;
      }
    }

    &:hover {
      color: black;

      i {
        color: black;
      }
    }
  }

  .el-menu-item {
    color: rgba(0, 0, 0, .65);

    &:focus {
      background-color: #E2F3FE;
      color: #237FFA;

      i {
        color: #237FFA;
      }
    }

    &:hover {
      background: #E2F3FE;
      color: #237FFA;

      i {
        color: #237FFA;
      }
    }
  }

  .el-submenu.is-active .el-submenu__title {
    background-color: transparent;
    color: black;

    i {
      color: black;
    }
  }

  .el-menu-item.is-active {
    background: #E2F3FE;
    color: #237FFA;

    i {
      color: #237FFA;
    }
  }
}

.left-menu {
  height: 100%;
  width: 216px;
  display: flex;
  flex-direction: column;

  .el-menu {
    flex: 1;
    overflow: auto;
    background-color: transparent;
    border-color: transparent;
    color: black;

    i[class~=iconfont] {
      margin-right: 5px;
      vertical-align: middle;
      width: 24px;
      text-align: center;
      display: inline-block;
    }
  }

  .logo {
    height: 56px;
    line-height: 56px;
    font-size: 0;
    vertical-align: middle;
    border-bottom: 0.5px solid #EBEDF0;

    img {
      height: 50px;
      vertical-align: middle;
    }
  }

  &.left-menu--collapse {
    width: auto;

    .logo {
      text-align: center;
      padding-left: 0px;
    }
  }
}
/* 文字 */
.el-submenu.is-active > .el-submenu__title {
  color: #237FFA !important;
}
/* icon图标也跟着变 */
.el-submenu.is-active > .el-submenu__title i {
  color: #237FFA !important;
}
.bottom{
  border-top: 1px solid #EBEDF0;
  text-align: center;
  height: 80px;
  padding: 10px 0 10px;
  cursor: pointer;
  p{
    margin: 0;
    color: #5F6A7A;
    font-size: 12px;
    &:nth-child(1){
      font-size: 14px;

      img{
        margin-right: 4px;
        width: 12px;
        vertical-align: middle;
        height: 12px;
        margin-top: -2px;
      }
    }
  }
}
</style>
