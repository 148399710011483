<template>
  <div class="layout">
    <dashboard v-if="isFullScreen" :isFullScreen.sync="isFullScreen">
    </dashboard>
    <DashboardHospital v-if="isFullScreenHosp" :isFullScreen.sync="isFullScreenHosp" />
    <LeftMenu v-show="!isFullScreen && !isFullScreenHosp" :isCollapse="isCollapse" ref="leftMenuRef"></LeftMenu>
    <el-container v-show="!isFullScreen && !isFullScreenHosp" v-if="isLoaded" class="container">
      <Header ref="headerRef" @openGuideModel="openGuideHandle" @collapse="(val) => (isCollapse = val)"
        @fullScreenChanged="handleFullscreen" @refreshAllTabs="refreshAllTabs"></Header>
      <el-main>
        <div class="container__content">
          <router-tab :contextmenu="contextmenu" restore append="next" restore-watch :max-alive="16">
            <template #default="tab">
              <span class="router-tab__item-title" :title="tab.tips">
                {{ tab.title }}
              </span>
              <i v-if="tab.closable" class="router-tab__item-close" @click.prevent="tab.close" />
            </template>
          </router-tab>
        </div>
      </el-main>
    </el-container>
    <!-- <div v-if="guideBoxFlag" class="guide_box" @click="openGuideHandle">
      <i class=" iconfont icon-xinshouzhiyin"></i>
      <div class="guide_text">新手指引</div>
    </div> -->
    <el-dialog class="welcome_dialog" :visible.sync="welcomeDialog" width="42%" :close-on-click-modal="false"
      :show-close="false" center>
      <div class="guideImg">
        <img :src="require('@/assets/guide.png')" />
      </div>
      <div class="begin_guide">
        <el-button class="begin_guide_btn" @click="beginGuideHandle">查看功能</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="footer_btn">
          <el-checkbox v-model="havedRead">下次登录不再提示</el-checkbox>
        </div>
        <div class="footer_btn close_btn" @click="closeGuideHandle">关闭</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 新手引导页
import Driver from '@/driverjs/';
import '@/driverjs/driver.scss';
import _ from 'lodash';
import GUIDEJSON from './guideJSON.js';
import UserApi from '@/api/user';
import Dashboard from '../Dashboard';
import DashboardHospital from '../DashboardHospital';

import LeftMenu from './leftMenu.vue';
import Header from './header.vue';
import { mapGetters } from 'vuex';

import { isFullScreen as checkIsFullScreen } from '@/utils/fullScreen';

const progessStepIds = [
  'customerlist',
  'customercontactPersonlist',
  'devicelist',
  'systememployeelist',
  'workOrderlist',
];
export default {
  name: 'layout',
  components: {
    LeftMenu,
    Header,
    Dashboard,
    DashboardHospital,
  },
  data () {
    return {
      progessStepIds,
      isCollapse: false,
      keepAliveComponentInstance: null,
      driver: null,
      welcomeDialog: false,
      guideBoxFlag: true,
      havedRead: false,
      guidejson: _.cloneDeep(GUIDEJSON),
      permissionStepMap: [],
      isFullScreen: false,
      isFullScreenHosp: false,
    };
  },
  computed: {
    ...mapGetters(['menuTree', 'menuIdMap']),
    isLoaded () {
      return this.menuTree && this.menuTree[0];
    },
    contextmenu () {
      return [
        {
          id: 'refresh',
          icon: 'el-icon-refresh',
        },
        {
          id: 'close',
          icon: 'el-icon-close',
        },
        {
          id: 'closeOthers',
          icon: 'el-icon-circle-close',
        },
        {
          id: 'closeRights',
          icon: 'el-icon-right',
        },
      ];
    },
  },
  created () {
    const permissionMap = Object.values(this.menuIdMap).map((i) => i.authCode.replace(/:|_/g, ''));
    // 步骤权限过滤
    this.guidejson.forEach((i) => {
      if (permissionMap.includes(i.vid)) {
        const newItem = _.cloneDeep(i);
        delete newItem.children;
        this.permissionStepMap.push(newItem);
        if (i.children && i.children.length) {
          i.children.forEach((child) => {
            if (permissionMap.includes(child.permission)) {
              this.permissionStepMap.push(child);
            }
          });
        }
      }
      if (i.noAuth) {
        this.permissionStepMap.push(i);
      }
    });

    // 初始化步骤浮层权限过滤
    const stepIds = {};
    progessStepIds.forEach((i) => {
      stepIds[i] = permissionMap.includes(i);
    });
    localStorage.stepIds = JSON.stringify(stepIds);

    this.handleWindowResize = () => {
      this.isFullScreen = this.isFullScreen && checkIsFullScreen();
      this.isFullScreenHosp = this.isFullScreenHosp && checkIsFullScreen();
    };
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  mounted () {
    // this.$nextTick(() => {
    // let userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : null;
    // if (!userInfo || userInfo?.guide !== '2') {
    //   this.welcomeDialog = true;
    //   this.goFirstStep();
    // } else {
    //   this.guideBoxFlag = false;
    // }
    // });
    if (this.$refs.keepAliveContainer) {
      this.keepAliveComponentInstance = this.$refs.keepAliveContainer.childNodes[0].__vue__;
    }
  },
  methods: {
    refreshAllTabs () {
      this.$tabs.items = [];
      this.$router.push({
        path: '/workbench',
      });
      window.location.reload();
    },
    goFirstStep () {
      [...document.querySelectorAll('.el-menu-item')].forEach((i) => {
        i.style['z-index'] = 100;
      });
      this.$router.push({
        path: this.permissionStepMap[0].pathName,
      });
    },
    beginGuideHandle () {
      this.welcomeDialog = false;
      this.initGuidePage();
    },
    closeGuideHandle () {
      if (this.havedRead) {
        this.sendHavedReadHandle(1);
      } else {
        this.welcomeDialog = false;
      }
      this.tipGuideHandle();
    },
    sendHavedReadHandle (type) {
      let userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : null;
      UserApi.sendHavedRead({
        id: userInfo?.id,
        guide: '2',
      })
        .then(() => {
          userInfo.guide = '2';
          localStorage.userInfo = JSON.stringify(userInfo);
        })
        .finally(() => {
          if (type === 1) this.welcomeDialog = false;
        });
    },
    tipGuideHandle () {
      this.$notify({
        title: '温馨提示',
        message: '已关闭引导，您可随时点击右上角“帮助中心-新手引导”重新观看。',
      });
    },
    // 菜单多层级处理
    flatMenu () { },
    openGuideHandle () {
      this.$refs.leftMenuRef.gobackTop();
      this.goFirstStep();
      // this.welcomeDialog = true;
    },
    initGuidePage () {
      const driverStep = [];
      this.permissionStepMap.forEach((item) => {
        if (item.pathName) {
          driverStep.push({
            element: `#${item.vid}`,
            popover: {
              title: ' ',
              description: item.tips,
              position: item.pst,
            },
            restOption: {
              pathName: item.pathName,
              parentId: item.parentId,
            },
          });
          if (item.children && item.children?.length) {
            item.children.forEach((i) => {
              driverStep.push({
                element: `#${i.vid}`,
                popover: {
                  title: ' ',
                  description: i.tips,
                  position: i.pst,
                },
              });
            });
          }
        } else {
          driverStep.push({
            element: `#${item.vid}`,
            popover: {
              title: ' ',
              description: item.tips,
              position: item.pst,
            },
            restOption: {
              parentId: item.parentId,
              bgColor: item.bgColor,
              needDrop: item.needDrop,
            },
          });
        }
      });
      this.driver = new Driver({
        allowClose: false, // 禁用外部点击关闭
        doneBtnText: '下一步 &gt;', // 最后一个按钮的文本
        closeBtnText: '关闭引导', // 关闭按钮文本
        stageBackground: 'transparent', // 高亮显示的元素的背景色
        nextBtnText: '下一步 &gt;', // 下一步
        prevBtnText: '', // 上一步
        showProgress: true, // 展示浮层进度
        onReset: (el, { checkFlag, triggerIsDone }) => {
          this.golastPage(checkFlag, triggerIsDone);
        },
        onNext: (el, next) => {
          this.goNextGuide(el, next);
        },
        onNextPath: (step) => this.goNextPath(step),
        onNextDropdown: (step) => {
          this.nextDownHandle(step);
        },
        onNeedKeepDorp: (step) => { this.keepDrop(step); },
      });
      this.driver.defineSteps(driverStep);
      this.driver.start(); // 启动
    },
    initLastGuidePage () {
      this.$nextTick(() => {
        const driverStep = [
          {
            element: '#guide_help_btn',
            popover: {
              title: ' ',
              description:
                '新手引导已完成，后续您可以在此处重新观看新手引导，如您下次不再观看可勾选下方“下次登录不再提示”。',
              position: 'left',
            },
            restOption: {
              haveChecked: true,
            },
          },
        ];
        this.driver = new Driver({
          allowClose: false, // 禁用外部点击关闭
          doneBtnText: '完成', // 最后一个按钮的文本
          closeBtnText: '完成', // 关闭按钮文本
          stageBackground: '#fff', // 高亮显示的元素的背景色
          // nextBtnText: '下》', // 下一步
          onReset: (Ele, { checkFlag }) => {
            this.readDoneHandle(checkFlag);
          },
        });
        this.driver.defineSteps(driverStep);
        this.driver.start(); // 启动
      });
    },
    readDoneHandle (checkFlag) {
      if (checkFlag) {
        this.sendHavedReadHandle();
      }
    },
    golastPage (checkFlag, triggerIsDone) {
      if (triggerIsDone) {
        setTimeout(() => {
          this.initLastGuidePage();
        }, 100);
      } else {
        if (checkFlag) {
          this.sendHavedReadHandle();
        }
        this.tipGuideHandle();
      }
    },
    goNextGuide () {
      [...document.querySelectorAll('.el-menu-item')].forEach((i) => {
        i.style['z-index'] = 100;
      });
    },
    goNextPath (step) {
      const pathName = step.options?.restOption?.pathName;
      if (step && pathName) {
        this.$refs.leftMenuRef?.highCurRoute(step);
        return this.$router.push({
          path: pathName,
        });
      }
    },
    nextDownHandle (step) {
      this.$refs.headerRef?.showDropdown(step);
    },
    keepDrop (step) {
      this.$refs.headerRef?.showDropdown(step);
    },
    handleFullscreen (type) {
      if (type === 2) {
        this.isFullScreenHosp = true;
        this.isFullScreen = false;
      } else {
        this.isFullScreen = true;
        this.isFullScreenHosp = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
  display: flex;
  position: relative;

  >.guide_box {
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 200px;
    width: 34px;
    height: auto;
    padding: 8px 6px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 0px rgba(36, 40, 50, 0.08);
    border-radius: 4px;
    border: 1px solid #EFEFEF;
    z-index: 900;

    &:hover {
      color: #0F89FF;
    }

    .iconfont {
      display: block;
      font-size: 22px;
    }

    .guide_text {
      margin-top: 6px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      padding-left: 4px
    }
  }

  >.container {
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    >.el-main {
      background-color: rgb(240, 242, 245);
      display: flex;
      flex-direction: column;
      padding: 0;

      .container__content {
        flex: 1;
        height: 100%;

        /deep/.router-tab {
          height: 100%;

          .router-tab__header {
            background-color: white;
            height: 56px;
            // line-height: 56px;
            border-left: 0.5px solid #EBEDF0;
            padding: 0 3px;
            display: flex;
            align-items: center;

            .router-icon {
              cursor: pointer;

              &:hover {
                color: #237ffa;
                transform: scale(1.2);
              }
            }

            .router-tab__nav {
              align-items: center;

              .router-tab__item {
                background: white;
                border-radius: 4px;
                margin-right: 6px;
                padding: 0 16px;
                height: 32px;
                border: 1px solid #eaecef !important;

                &.is-active,
                &:hover {
                  color: $primary-color;
                  background-color: #E2F3FE;

                  .router-tab__item-close {

                    &:before,
                    &:after {
                      background-color: #237FFA;
                    }
                  }
                }

                .router-tab__item-close {
                  margin-left: 4px;
                  width: 13px;
                  color: #237FFA;
                }
              }
            }

            .router-tab__scrollbar-thumb:hover,
            .router-tab__scrollbar.is-dragging .router-tab__scrollbar-thum {
              background-color: $primary-color;
            }
          }

          >.router-tab__container {
            background: transparent;
            padding: 8px 8px 0;
          }

          .router-tab__contextmenu-item:active,
          .router-tab__contextmenu-item:hover {
            color: $primary-color;
          }
        }
      }

      .container_dashboard {
        margin: 0 -12px;
      }
    }
  }
}

.moveR-enter-active,
.moveR-leave-active {
  transition: all 0.3s linear;
  transform: translateX(0);
}

.moveR-enter,
.moveR-leave {
  transform: translateX(100%);
}

.moveR-leave-to {
  transform: translateX(100%);
}

/deep/ .welcome_dialog {
  color: #eee;

  .el-dialog {
    border-radius: 6px;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;

    .guideImg {
      // height: 300px;
      width: 100%;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .begin_guide {
      position: absolute;
      bottom: 16.7%;
      right: 6.6%;
      width: 100%;
      text-align: right;

      &_btn {
        font-size: 14px;
        letter-spacing: 1px;
        color: #0f89ff;
      }
    }
  }

  .el-dialog__footer {
    border: none;
    position: absolute;
    right: 0;
    bottom: -50px;
    padding-right: 0;

    .footer_btn {
      cursor: pointer;
      font-size: 14px;
      position: relative;
      display: inline-block;
      margin-left: 40px;

      &.close_btn:before {
        display: inline-block;
        position: absolute;
        left: -20px;
        top: 5px;
        content: ' ';
        height: 15px;
        width: 0;
        border-left: 1px solid #ccc;
        right: 10px;
      }

      .el-checkbox {
        color: #fff;
        // font-size: 14px;
      }

      .el-checkbox__input.is-checked+.el-checkbox__label {
        color: #fff;
      }
    }
  }
}
</style>
