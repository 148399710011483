<template>
  <div class="header">
    <div class="left-datetime">{{ dateTime }}</div>
    <div class="middle-title">{{ title }}</div>
    <div class="right-group">
      <div class="weather"></div>
      <div class="quit" @click="exit">
        <i class="el-icon-switch-button"></i>
        退出
      </div>
    </div>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  name: 'dashboard-header',
  data () {
    return {
      dateTime: '',
      timer: null,
    };
  },
  computed: {
    ...mapGetters(['currentUserInfo']),
    title () {
      return `${this.currentUserInfo.unitAbbreviation || '小紫医疗客户'}运营服务看板`;
    },
  },
  methods: {
    getDateTime () {
      this.dateTime = dayjs().format('YYYY年MM月DD日 HH:mm:ss');
    },
    exit () {
      this.$emit('update:isFullScreen', false);
    },
  },
  mounted () {
    this.timer = setInterval(this.getDateTime, 1000);
  },
  beforeDestroy () {
    clearInterval(this.timer);
  },
};
</script>
<style lang="scss" scoped>
.header {
  box-sizing: border-box;
  width: 100%;
  height: vh(90);
  background-image: url('../../../assets/imgs/dashboard/headnavigation.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .left-datetime {
    height: vh(16);
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #c8ddff;
    line-height: vh(16);
    position: absolute;
    left: vw(40);
    bottom: vh(16);
  }

  .middle-title {
    height: vh(30);
    font-size: 30px;
    padding-top: 11px;
    font-weight: 600;
    color: #d3e6ff;
    line-height: vh(30);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .right-group {
    position: absolute;
    right: 0;
    bottom: vh(8);

    .quit {
      box-sizing: border-box;
      height: vh(16);
      font-size: 16px;
      font-weight: 400;
      color: #FF6A49;
      line-height: vh(16);
      margin: vh(10) vw(12);
      cursor: pointer;
    }
  }
}
</style>
